<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('shoplist_xq')">基本信息</span>
      <span id="fzcolor">结算信息</span>
      <span @click="toTypeUrl('add_shoplist_pz')">配置信息</span>
    </div>
    <div class="header" v-else>
      <span @click="toUrl('add_shoplist', false)">基本信息</span>
      <span id="fzcolor">结算信息</span>
      <span @click="toUrl('add_shoplist_pz')">配置信息</span>
      <span @click="toUrl('add_shoplist_syr')">受益人信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">结算信息</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 171px">
            <p>账户名</p>
          </td>
          <td style="width: 163px">
            <p>账号</p>
          </td>
          <td style="width: 95px">
            <p>联行号</p>
          </td>
          <td style="width: 269px">
            <p>账户类型</p>
          </td>
          <td style="width: 95px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="width: 171px">
            <p>{{ v.accountName }}</p>
          </td>
          <td style="width: 163px">
            <p>{{ v.accountNo }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ v.interBranchesNo }}</p>
          </td>
          <td style="width: 269px">
            <p>{{ accountType[v.accountType] }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="editTab(v, i)">编辑</i
              ><i class="lianjie" @click="delTab(i, v)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addTab" v-if="tabData.length < 1">
      <span>+</span> 新增结算信息
    </h6>
    <div class="btn" v-if="pageType == 'edit'">
      <el-button @click="toTypeUrl('add_shoplist')" class="back"
        >上一步</el-button
      >
      <el-button
        @click="toTypeUrl('add_shoplist_pz')"
        class="next"
        type="primary"
        >下一步</el-button
      >
    </div>
    <div class="btn" v-else>
      <el-button @click="toUrl('add_shoplist', false)" class="back"
        >上一步</el-button
      >
      <el-button @click="toUrl('add_shoplist_pz')" class="next" type="primary"
        >下一步</el-button
      >
    </div>
    <el-dialog title="结算账户" :visible.sync="overAccountShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>账户类型</div>
          <el-select
            v-model="formData.accountType"
            placeholder="选择账户类型"
            tabindex="1"
          >
            <el-option
              v-for="(v, i) in accountType"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>账户</div>
          <el-input
            v-model="formData.accountNo"
            maxlength="128"
            placeholder="输入账户"
            tabindex="3"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>账户名</div>
          <el-input
            v-model="formData.accountName"
            maxlength="128"
            placeholder="输入账户名"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title">
            <i v-show="formData.accountType == 'COMPANY'">*</i>联行号
          </div>
          <el-input
            v-model="formData.interBranchesNo"
            maxlength="128"
            placeholder="输入联行号"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" :disabled="buttonFlag" @click="sureAdd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      overAccountShow: false, //结算账户展示
      formData: {
        accountNo: "", //true	结算账户
        accountName: "", //true	结算账户名称
        interBranchesNo: "", //false	联行号（账户类型为企业时必填）
        accountType: "", //true	账户类型
      },
      accountType: {
        COMPANY: "企业",
        PERSONAL: "个人",
      },
      tabData: [],
      editIndex: "",
      pageType: "", //页面状态
      buttonFlag: false, //防止重复点击
    };
  },
  computed: {
    ...mapState({
      storesApiDetail: "user_storesApiDetail",
    }),
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.storesApiDetail) {
        if (this.storesApiDetail.account) {
          this.tabData[0] = this.storesApiDetail.account;
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setStoresApi: "user_setStoresApi",
    }),
    //获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.tabData = res.resultData.account;
        }
      });
    },
    // 添加结算账户展示
    addTab() {
      this.formData = {
        accountNo: "",
        accountName: "",
        interBranchesNo: "",
        accountType: "",
      };
      this.editIndex = null;
      this.buttonFlag = false;
      this.overAccountShow = true;
    },
    // 修改结算账户
    editTab(formData, index) {
      this.formData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.buttonFlag = false;
      this.overAccountShow = true;
    },
    //删除结算账户
    delTab(index, value) {
      this.$confirm("此操作将删除该结算账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delSettlements(this.$route.query.id, value.settlementId).then(
              (res) => {
                if (res) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.tabData.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算账户
    sureAdd() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.accountType) {
        this.$message.error("账户类型必选");
        return false;
      }
      if (!data.accountNo) {
        this.$message.error("结算账户必填");
        return false;
      }
      if (!data.accountName) {
        this.$message.error("结算账户名称必填");
        return false;
      }
      if (data.accountType === "COMPANY") {
        if (!data.interBranchesNo) {
          this.$message.error("联行号必填");
          return false;
        }
      }
      this.buttonFlag = true;
      if (this.$route.query.pageType == "edit") {
        data.settlementId = data.settlementId ? data.settlementId : 0;
        setSettlements(this.$route.query.id, data).then((res) => {
          if (res) {
            this.$message.success("保存成功");
            this.getDetail();
            this.buttonFlag = false;
            this.overAccountShow = false;
          }
        });
      } else {
        if (this.editIndex !== null) {
          this.tabData[this.editIndex] = data;
        } else {
          this.tabData.push(data);
        }
        this.$message.success("保存成功");
          this.buttonFlag = true;
        this.overAccountShow = false;
      }
    },
    // 页面跳转
    toUrl(name, bool = true) {
      if (bool) {
        if (this.tabData.length <= 0) {
          this.$message.error("结算账户必填");
          return false;
        }
      }
      this.$router.push({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let data = {
        storeDetail: this.storesApiDetail
          ? this.storesApiDetail.storeDetail
          : null,
        account: this.tabData[0],
        config: this.storesApiDetail ? this.storesApiDetail.config : null,
        beneInfos:this.storesApiDetail ? this.storesApiDetail.beneInfos : null,
      };
      this.setStoresApi(data);
    }
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul_title i{
    color: #48b8b6;
    padding-right: 3px;
}
.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
